@import '../variables.less';
@import 'mixins.less';
@import '../mixins/grid.less';
@import '../mixins/responsive_utilities.less';
@import '~baseTheme/_common/toolbar/index.mobile.less';
@import '~baseTheme/_common/toolbar/action/index.mobile.less';
@import '~baseTheme/_common/toolbar/footer/index.mobile.less';
@import '~baseTheme/_common/toolbar/row/index.mobile.less';
@import (reference) '../../../../../../../node_modules/bootstrap/less/utilities.less';
@import (reference) '../../../../../../../node_modules/bootstrap/less/forms.less';
@import (reference) '../../../../../../../node_modules/bootstrap/less/navbar.less';

.mobile-elements () {
    .mobile-header-toolbar {
        top: @mobileHeaderHeight;
    }

    .mobile-toolbar-dropdown-toggle {
        .mobile-dropdown-toggle;
        position: absolute;
        top: 0;
        padding: 0 5px;
        border: 1px solid @mobileToolbarTextColor;
        &:after {
            content: "\f0d7";
            font-family: FontAwesome;
        }
    }

    .mobile-toolbar-back {
        float: left;
        .arrow-back;
    }

    .mobile-toolbar-forward {
        float: right;
        .arrow-forward;
    }

    .mobile-toolbar-aside {
        float: right;
    }

    .mobile-toolbar-filters {
        padding: 10px 21px !important;

        .mobile-toolbar-filter {
            width: 48%;
            padding: 5px 0;
            text-align: center;
            font-weight: bold;
        }
        .category-filter {
            background: @primaryBlueDarkest;
            border: 1px solid @primaryBlueDarkest;
            color: white;
            float: left;
            img {
                width: 14px;
                margin-right: 5px;
            }
            &.search-open {
                width: 25%;
                span {
                    display: none;
                }
            }
        }
        .search-filter {
            color: black;
            border: 1px solid @primaryGrayLight;
            float: right;
            &::before {
                content: "\f002";
                font-family: 'FontAwesome';
            }
        }
        .mobile-toolbar-search {
            width: 73%;
            float: right;
            margin-bottom: 0;
            opacity: 1;
            transition: width .2s linear;
            &.hidden {
                opacity: 0;
                width: 47%;
            }
            input {
                height: 32px;
                width: calc(100% - 30px);
                margin-bottom: 0;
            }
            button {
                height: 32px;
                left: -7px;
                &::before {
                    content: "\f002";
                    font-family: 'FontAwesome';
                }
            }
        }
    }

    .mobile-toolbar-filter-wrapper {
        padding: 10px 0px;
        margin: 0px -21px;
        background: white;

        .category-filter {
            display: inline-block;
        }
    }

    .mobile-help-text {
        color: @textSecondaryColor;
        text-align: center;
        margin-bottom: 0;
    }

    .body-content {
        .ui-accordion-header {
            .onp-row();
            overflow: hidden;
            padding: 10px (@gridGutterWidth / 2)  10px (@gridGutterWidth / 2 + 16);
            background-color: @primaryGrayLighter;
            border: 2px solid @primaryGrayLight;
            color: @primaryGray;
            font: normal 16px @baseFontFamily;
            margin-bottom: 10px;
            .ui-accordion-header-icon {
                left: 16px;
            }
            .page-subheading-aside {
                text-transform: none;
                em {
                    font-size: @baseFontSize;
                    font-family: @latoFontFamily;
                    margin-right: 5px;
                }
            }
        }

        .ui-accordion-header-active {
            font-weight: 600;
        }
        .ui-accordion-content {
            padding: 5px 0 15px;
        }
    }
}

.mobile-option {
    .mobile-option();
    .mobile-option-label {
        font-weight: bold;
        width: 100px;
        float: left;
        margin-bottom: 0;
        pointer-events: none;
    }
    .mobile-option-data {
        margin-left: 100px;
    }
}

.mobile-link {
    .mobile-link();
}

.mobile-link-forward {
    &::after {
        content: "\f054";
        font-family: FontAwesome;
        float: right;
    }
}

.mobile-link-back {
    &::before {
        content: "\f053";
        font-family: FontAwesome;
        padding-right: 5px;
    }
}

.mobile-button {
    &, &:hover, &:active, &:focus, &:visited {
        color: @mobileToolbarTextColor;
    }
    background: @mobileToolbarColor;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
}

.mobile-bar-button {
    display: block;
    text-align: center;
    background: @mobileToolbarColor;
    padding: 5px 10px;
    font-size: 16px;
    &, &:hover, &:active, &:focus, &:visited {
        color: @mobileToolbarTextColor;
    }
    &.arrow-back::before {
        float: left;
    }
    &.arrow-forward::after {
        float: right;
    }
}

.mobile-dropdown-toggle {
    &, &:hover, &:active, &:focus, &:visited {
        color: @mobileToolbarTextColor;
    }
    display: inline-block;
    height: 20px;
    background: @mobileToolbarColor;
    border-radius: 5px;
    margin-left: 10px;
    padding: 1px 5px;
    font-size: @fontSizeLarge;
}

.mobile-dropdown-menu {
    background: @mobileToolbarColor;
    border: none;
    text-transform: none;
    a {
        &, &:hover, &:active, &:focus, &:visited {
            color: @mobileToolbarTextColor;
        }
    }
}

.checkbox-simple.checkbox-big.mobile-option {
    padding: 0;
}
