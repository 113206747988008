@import "../../variables.less";

.select-simple {
    .selectboxit-container {
        span, .selectboxit-options a {
            min-height: @formElementHeight;
            line-height: @formElementHeight;
            em {
                font-family: @serifFontFamily;
                font-style: italic;
                font-size: inherit;
            }
        }
        .selectboxit-btn {
            border: 1px solid @primaryGrayLight;
            border-radius: 0;
            background: @white;
            transition: border .15s linear !important;
            &.selectboxit-open {
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
            }
            &.selectboxit-enabled {
                &, &:hover, &:focus, &:active {
                    background: @white;
                }
            }
            &:hover {
                border-color: @primaryBlue;
            }
        }
        .selectboxit-arrow-container {
            border-left: none;
            .selectboxit-arrow {
                border: none;
                top: 25%;
                right: 15px;
                &:before {
                    display: block;
                    font-size: 16px;
                    color: @primaryBlue;
                    content: "\f078";
                    font-family: FontAwesome;
                }
            }
        }
        .selectboxit-list {
            border: 1px solid @primaryBlueDark;
            border-radius: 0;
        }
        .selectboxit-option-first {
            border-radius: 0;
        }
        .selectboxit-option {
            background: none;
            border-top: 1px solid @primaryBlueDark;
            border-bottom: none;
            border-radius: 0;
            font-family: @latoFontFamily;
            &:hover {
                border-bottom: none;
                background-color: @primaryBlueLight;
                color: white;
            }
            .selectboxit-option-anchor {
                font-family: @latoFontFamily;
                font-size: @baseFontSize;
                color: @primaryGray;
                &:hover {
                    color: @black;
                }
            }
        }
        li.selectboxit-focus {
            border-top: 1px solid @primaryBlueDark;
            color: white;
            background: @primaryBlueLight;
            > .selectboxit-option-anchor {
                background: @primaryBlueLight;
            }
        }
        .selectboxit-text {
            color: @primaryGray;
            font-size: @baseFontSize;
            font-family: @latoFontFamily;
        }
    }
}
