.color-picker-label {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 0;
    transition: all .2s ease-in-out;
    cursor: pointer;
    &:hover {
        transform: scale(1.2);
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        border: 1px solid @white;
    }
    [type="radio"] {
        display: none;
    }
    &.color-picker-label-ffffff {
        border-top: 1px solid #dbdbdb;
        border-left: 1px solid #dbdbdb;
        &:hover {
            border: 1px solid #dbdbdb;
        }
    }
    &.color-picker-label-selected {
        text-align: center;
        padding-top: 3px;
        position: absolute;
        margin-top: -2px;
        right: 30px;
        transform: none;
        box-shadow: none;
        border: none;

        &.color-picker-label-ffffff {
            border: none;
            i {
                color: #2092C9;
                border: none;
            }
        }
    }
}
