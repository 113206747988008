@import '../variables.less';
@import '../mixins/mixins.less';
@import (reference) '../mixins/responsive_utilities.less';

body#myonp-status, body#myonp-order, body#myonp-vip,
body#myonp-ml, body#myonp-cal, body#profile, body#address {
    font-size: @baseFontSize;
    .body-content {
        font-size: @fontSizeSmall;
    }
}

body#myonp-ml {
    .col-center {
        background-color: inherit;
        .panel-container {
            padding: 0;
        }
    }
}

.myonp {
    &.profile-update {
        margin: 0;
    }
    h1, h2 {
        font: 400 16px @headerFontFamily;
        margin-bottom: 20px;
    }
}

form#change_profile_form.form-horizontal {
    .controls {
        margin-left: 161px;
    }
    .control-label {
        width: 130px;
    }
}

form.user-info-upd.form-horizontal {
    .controls {
        margin-left: 200px;
    }
    .control-label {
        width: 170px;
    }
}

h1.header-align {
    font-size: 26px;
}

.myonp-reg {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 26px;
    }
    .controls {
        input {
            width: 100%;
        }
    }
    #registration-form {
        label.error, .help-block {
            font-size: 11px;
            line-height: normal;
            margin-top: 0;
        }
        .btn-primary.btn-large {
            .onp-style(
                @xs: {
                    width: 100%;
                }
            );
        }
        input[type=submit] {
            margin-top: 30px;
        }
    }
    .btn-back {
        margin-bottom: 30px;
    }
}

.myonp-content {
    clear: both;
    display: block;
    .onp-style(
        @non-xs: {
            width: 565px;
            padding: 10px 75px;
        }
    );
    margin: auto;
    font-size: 1.083em;
    h1 {
        .title-tag;
        font-size: 2.923em;
        text-align: center;
        margin-bottom: 23px;
    }
    p {
        margin-bottom: 25px;
        &.hdr {
            margin-bottom: 5px;
        }
        &.text-org {
            color: @primaryOrangeActive;
        }
    }
    select {
        width: 100%;
        margin-bottom: 30px;
    }
    textarea {
        width: 395px;
        height: 100px;
        margin-bottom: 25px;
        padding: 10px;
    }
    .btn-row {
        margin-top: 30px;
    }
    a.cancel-hold {
        float: left;
        margin-top: 12px;
    }
    button, input[type="submit"] {
        float: right;
    }
    input[name="Go_Back"] {
        float: left;
    }
    .text-error {
        margin-bottom: 20px;
    }
}

#myonp-cancel-order.myonp-content {
    width: 700px;
    table td {
        padding: 0 5px;
        input[type="submit"] {
            float: none;
        }
    }
}

.account-remove-hold-page {
    .myonp-content {
        .onp-style(
            @non-xs: {
                padding: 22px 75px 10px;
            },
            @xs: {
                margin-bottom: 30px;
                .remove-hold-action {
                    .btn {
                        min-width: 150px;
                        padding: 10px 10px;
                    }
                }
            }
        );
    }
}

a.vr-promo {
    background: url("/assets/images/vip/vip_rewards_promo.png") no-repeat center center;
    background-size: contain;
    display: block;
    text-indent: -9999px;
    font-size: 1px;
    max-width: 189px;
    width: 100%;
    margin: 0 auto 30px;
    min-height: 243px;
}

#pickup-location-detail {
    display: none;
}


.acct-ord-item-preview {
    .onp-row(@gutter: 0);
}

form#shipping-update {
    h3 {
        font-size: 2.308em;
        margin-bottom: 25px;
    }
    input[type="text"] {
        width: 250px;
    }
    input#zip {
        width: 65px;
    }
    .buttons-grp {
        margin-bottom: 40px;
    }
    select {
        width: 160px;
    }
}

.account-container {
    .navigation-column {
        font-family: @headingsFontFamily;
    }
}

body#profile .pfcheck-content h2 {
    font-family: @headingsFontFamily;
}

.onp-style(
    @xs: {
        .xs-nowrap {
            white-space: nowrap;
        }
    }
);

// uk only
.use-classic-website {
    display: block;
    margin-bottom: 20px;
    margin-top: -20px;
}
