@import (reference) '~resources/less/themes/base/variables.less';

.zone-icon {
    width: 18px;
    height: 25px;
    float: left;
    margin-top: 5px;
    &.zone-icon-bleed {
        background: @bleedZoneColor;
    }
    &.zone-icon-safe {
        background: @safeZoneColor;
    }
}

.zone-text {
    margin-left: 25px;
}
