@import '../variables.less';

.action-in-progress-overlay,
.action-in-progress-full-screen-overlay {
    z-index: @overlayZIndex + 1;
    display: none;
    user-select: none;
    background-color: rgba(0, 0, 0, 0.3);

    &.is-active {
        display: block;
    }

    .progress-bar {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 220px ;
        height: 16px;
        margin-left: -110px;
        animation: move 0.5s linear infinite;
        border: 1px solid #0195e6;
        border-radius: 3px;
        background-image: repeating-linear-gradient(
            -45deg,
            #00b1f4,
            #00b1f4 11px,
            #00befb 10px,
            #00befb 20px
        );
        background-size: 28px 28px;
    }
}

.action-in-progress-overlay {
    position: absolute !important;
    top: 0;
    float: none !important;
    width: 100% !important;
    height: 100%;
    padding: 0 !important;
}

.action-in-progress-full-screen-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 28px 0;
    }
}
