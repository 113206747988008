@import '../variables.less';
@import './design_face_type.less';

.design-face-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    max-width: 100%;

    &.order-preview-thumbnail {
        img {
            width: 100%;
            height: 100%;
        }
    }

    img {
        vertical-align: top;
    }
    .loading {
        height: 100%;
        padding-top: 20px;
        text-align: center;
    }
    &.zoomed-img,
    &.booklet-preview {
        overflow: visible;
    }
}

img[data-src] {
    display: none;
}

.hidden-img {
    visibility: hidden;
}

.guide-lines {
    pointer-events: none;
    position: absolute;
    z-index: @guideLinesZIndex - 1; //below the mailing services guides
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.mailing-services-guides {
    position: absolute;
    z-index: @guideLinesZIndex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.hole-drilled {
    &:before {
        .border-radius(50%);
        content: "";
        border: 1px solid @borderColor;
        display: block;
        height: 7%;
        width: 7%;
        background-color: @bodyBackground;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 6%;
        z-index: @holeDrillingZIndex;
    }
}

.order-default-preview {
    width: 140px;
    height: 82px;
}
