@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';

.tabs-bar {
    font-family: @headingsFontFamily;
    width: 100%;
    .onp-style(
        @xs: {
            margin: 0;
            display: block;
        }
    );
    .prod-tabs {
        border-bottom: 1px @tabColorActive solid;
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        .onp-style(
            @xs: {
                display: block;
                width: 100%;
                border-bottom: none;
            }
        );
        li {
            display: flex;
            background-color: @tabColorInactive;
            border: 1px @primaryGray solid;
            .onp-style(
                @xs: {
                    display: block;
                    width: 100%;
                    padding: 0;
                    height: auto;
                    border: none;
                }
            );
            &.active {
                .active-tab();
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin: 0;
                .onp-style(
                    @xs: {
                        display: block;
                        text-align: left;
                    }
                );
                a {
                    display: block;
                    width: 100%;
                    color: @primaryGray;
                    padding-left: 10px;
                    padding-right: 10px;
                    text-align: center;
                    text-decoration: none;
                    text-transform: uppercase;
                    .onp-style(
                        @xs: {
                            display: block;
                            text-align: left;
                        }
                    );
                }
            }
        }
    }
}

.active-tab () {
    background-color: @primaryBlue;
    border: none;
    &::after {
        top: 90%;
        left: 50%;
        border: 20px solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: @primaryBlue;
        margin-left: -20px;
    }
    a {
        color: @white;
        text-align: center;
        text-decoration: none;
    }
}
