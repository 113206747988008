@import '../variables.less';

body#cartpage, body#checkoutpage {
    label {
        font-size: inherit;
        &.error {
            border: 0;
        }
    }
    #shipping_stateError {
        .onp-style(
            @xs: {
                margin-top: 14px;
            }
        );
    }
    #shipping_stateError, #billing_stateError {
        width: 115px;
    }
}

form {
    margin: 0;
}

ul.item-details {
    > li {
        margin-bottom: 9px;
        .clearfix;
        &.hdr {
            border: none;
        }
        h4 {
            font-size: 1em;
            margin: 10px 0 1px 0;
        }
        &.total {
            padding-top: 30px;
            span.tot-hdr {
                float: left;
            }
            span.tot-amt {
                position: relative;
                float: right;
                font-weight: bold;
                font-size: 1.154em;
                margin-top: 0;
                min-width: 40px;
            }
        }
        .btn {
            margin-bottom: 12px;
        }
    }
    & > li.subtotal {
        font-family: @altHeaderFontFamily;
        padding-top: 30px;
        span.subtotal-hdr {
            font-size: 17px;
            font-weight: bold;
            float: left;
        }
        span.subtotal-amt {
            position: relative;
            float: right;
            font-weight: bold;
            font-size: 1.154em;
            margin-top: 0;
            min-width: 40px;
        }
    }
}

#checkoutForm {
    .checkout-section ul {
        margin-left: 10px !important;
    }
    .selectboxit-container > ul {
        margin-left: 0 !important;
    }
}

span {
    &.prc {
        display: block;
        float: right;
    }
    &.norm {
        font-family: @baseFontFamily;
        font-style: normal;
    }
}

.dsn-check a.btn:before {
    background: url("/assets/images/cart/cart_sprite.png") no-repeat;
}

body#checkoutpage, body#shippingUpgradeConfirmation,
body#profile, body#address, div#order-details-dialog, body#error-page, div#edit-profile-dialog, div#change-shipping-address-dialog,
div#new-shipping-address-dialog {
    .body-content {
        font-size: 1.083em;
        > h1 {
            margin-bottom: 4px;
            width: 80%;
        }
        h3 {
            margin: 0 8px 20px 0;
            display: inline-block;
            + .info-text {
                text-transform: none;
                margin-bottom: 10px;
                padding-left: 5px;
                &:before {
                    content: "*";
                    position: relative;
                    left: -5px;
                    color: @primaryOrangeActive;
                }
            }
        }
        h4 {
            font-size: 1.23em;
        }
    }
    &.ui-dialog-content, .ui-dialog-content {
        .cc-exp {
            .selectboxit-container {
                width: 48% !important;
                max-width: 133px;
                .onp-style(@non-xs: {
                    margin-right: 10px;
                });
            }
        }
        select, .selectboxit-container, .selectboxit {
            width: 100% !important;
            max-width: 280px;
        }
        #shipping-address-select-newSelectBoxItContainer {
            &, .selectboxit, .select {
                max-width: 453px;
            }
        }
        .onp-style(
            @xs: {
                .f-label {
                    width: 40%;
                    text-align: left;
                }
                .f-data {
                    margin-left: 0;
                    width: 60%;
                    display: inline-block;
                }
            }
        );
    }
    form#checkoutForm {
        margin-bottom: 40px;
    }
    .f-label {
        .onp-style(
            @xs: {
                text-align: left;
            }
        );
        width: 155px;
        float: left;
        margin-top: 5px;
        text-align: left;
        label {
            text-indent: 10px;
        }
        &.lt {
            text-align: left;
        }
        &.tall {
            margin-top: 9px;
        }
    }
    .f-data {
        margin-left: 195px;
        position: relative;
        .text-simple {
            width: 100%;
            max-width: 280px;
        }
        &.logos span {
            margin-bottom: 0;
        }
    }
    .f-help {
        font-size: 12px;
        line-height: 20px;
        margin: 0;
    }
    .credit-card-cvv {
        .text-simple {
            width: 57px;
        }
        .cvv {
            display: inline-block;
            margin: 0 5px;
        }
    }
    .control-group {
        margin-bottom: 15px;

        &:after {
            content:"";
            display:block;
            clear:both;
        }
    }
    ul.item-details {
        float: none;
        width: 340px;
    }
    .logos.withtext {
        line-height: 1.923em;
    }
    #cart-shipping, #cart-billing, #billing-block {
        .f-label {
            margin-top: 7px;

            label {
                display: inline;
                text-indent: 0;
            }
        }
    }
}

#ship-to #ship-del {
    margin-top: 30px;
}

.order-item {
    position: relative;
    padding-bottom: 35px;
    ul.item-opts {
        margin-left: 15px;
    }
    h5 {
        font-size: inherit;
        white-space: nowrap;
    }
}

.banner-flag, .banner-flag:before, .banner-flag:after {
    background-image: url("/assets/images/cart/banner_flag_bg.png");
}

.banner-flag {
    .onp-column(@md: 10, @lg: 10);
    .onp-column-offset(@md: 1, @lg: 1);
    padding-top: 25px;
    padding-bottom: 25px;
    background-repeat: repeat-x;
    width: 100%;
    height: 93px;
    position: relative;
    text-align: center;
    font-size: 1.154em;
    line-height: 1.5em;
    &:before {
        content: "";
        background-position: 0 -100px;
        position: absolute;
        width: 30px;
        height: 93px;
        top: 0;
        left: -30px;
    }
    &:after {
        content: "";
        background-position: -30px -100px;
        position: absolute;
        width: 30px;
        height: 93px;
        top: 0;
        right: -30px;
    }
}

#cart-empty {
    text-align: center;
    min-height: 300px;
    padding-top: 100px;
    h2 {
        .title-tag;
        font-size: 3em;
        margin-bottom: 30px;
    }
    p {
        font-size: 1.167em;
    }
}

#shipping-upgrade-display {
    text-align: left;
    span.prc {
        display: none;
    }
    h5 {
        .title-tag;
        font-size: 2.308em;
        margin: 0 0 20px 0;
    }
}

.ui-dialog {
    &.noTitleStuff {
        .onp-style(
            @xs: {
                > .ui-dialog-content.ui-widget-content {
                    padding: 0 10px 30px;
                    min-width: inherit;
                    width: 100%;
                }
            }
        );
    }
}

.discount-disclaimer {
    font-size: @baseFontSize;
    font-style: italic;
    font-weight: normal;
}

#order-total {
    font-weight: @headingsFontWeight;
}
