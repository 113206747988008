body#myonp-vip {
	font-size: @baseFontSize;
}

a.vr-promo {
    background: url("/assets/images/vip/vip_rewards_promo.png") no-repeat;
    display: block;
    text-indent: -9999px;
    font-size: 1px;
    width: 189px;
    margin-bottom: 30px;
    min-height: 250px;
}
