@import '../../../../../../../node_modules/intl-tel-input/build/css/intlTelInput.css';

.f-data .iti,
.control-group .f-data .iti {
    // for intl-tel-input field
    width: 100%;
}

.iti__flag {background-image: url("/dist/OvernightPrints/intl-tel-input/img/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/dist/OvernightPrints/intl-tel-input/img/flags@2x.png");}
}
