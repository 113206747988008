@import (reference) '~resources/less/themes/base/variables.less';

.radio-simple-out (@color) {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 15px;
    height: 15px;
    border: 2px solid @color;
    border-radius: 50%;
    background: @white;
}

.radio-simple-in (@color) {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: @color;
    opacity: 0;
    transition: all .2s;
}

// simplified radio input
.radio-simple[type="radio"] {
    display: none;
}

.radio-simple {
    position: absolute;
    vertical-align: top;
    width: 25px;
    height: 24px;
    margin: 0 3px 0 0;
}

.radio-simple + label {
    cursor: pointer;
    margin: 0 0 5px;
    width: 100%;
    position: relative;
    padding: 0 0 0 25px;
    height: auto;
}

.radio-simple + label:before {
    .radio-simple-out(@primaryBlueDark);
}

.radio-simple:disabled + label:before {
    .radio-simple-out(@grayLight);
}

.radio-simple + label:after {
    .radio-simple-in(@primaryBlueDark);
}

.radio-simple:disabled + label:after {
    .radio-simple-in(@grayLight);
}

.radio-simple:checked + label:after {
    opacity: 1;
}

.radio-simple:not(:disabled):not(:checked) + label:hover:after {
    opacity: 0.7;
}

.radio-simple:focus + label:before {
    box-shadow: 0 0 0 3px fade(@primaryBlueDark, 50%);
}
