@import '../variables.less';

.mobile-option () {
    position: relative;
    > span, label {
        .mobile-pill();
    }
    input[type=radio], input[type=checkbox] {
        display: none;
    }
    :checked ~ span {
        background: @primaryBlueActive;
        color: @white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    :disabled ~ span {
        opacity: 0.7;
    }
    [type=checkbox] {
        ~ span {
            padding-right: 35px;
        }
        &:checked ~ span::after {
            content: "\f00c";
            font-family: FontAwesome;
            position: absolute;
            right: 15px;
        }
    }
    [type=radio] {
        &:checked + label {
            .active-input();
            &:after {
                content: '\f00c';
                font-family: FontAwesome;
                font-size: 200%;
                position: absolute;
                right: 8px;
                text-decoration:none;
                top: 50%;
                margin-top: -11px;
            }
        }
        &:not(checked) + label {
            border: 2px solid @primaryBlueDark;
        }
        &:not(checked) + label:hover {
            .active-input();
        }
    }
    .active-input {
        background-color: @primaryBlueDark;
        color: @white;
    };
    label {
        border: 2px solid @primaryBlueHover;
        cursor: pointer;
        display: inline-block;
        font-size: 100%;
        font-weight: normal;
        line-height: 21px;
        margin-top: 5px;
        padding: 5px 50px 5px 10px;
        width: 90%;
        box-sizing: border-box;
        font-family: @headerFontFamily;
        position: relative;
    }
}

.mobile-link () {
    .mobile-pill();
    width: 100%;
    font-weight: normal;
    font-family: inherit;
    text-align: left;
    margin-bottom: 10px;
    &, &:visited, &:hover, &:active {
        color: @textColor;
    }
}

.mobile-pill () {
    display: block;
    padding: 10px 5px;
    border-radius: 8px;
    cursor: pointer;
    background: @white;
    border: 1px solid @primaryBlueActive;
    font-size: @baseFontSize;
}
