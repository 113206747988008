@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/grid.less';

.login-flash-message {
    font-size: 20px;
    text-align: center;
    padding: 5px;
    margin-bottom: 15px;
    .close {
        display: none;
    }
}

.forgot-password {
    color: @primaryBlueActive;
    text-decoration: none;
    &:hover {
        color: @primaryBlue;
    }
}

.login-block-title {
    font: 400 24px @latoFontFamily;
    padding-left: 12px;
}

.checkout-block {
    .secure-icon {
        margin-right: 10px;
        + span {
            font-size: 19pt;
            color: #777;
            vertical-align: middle;
        }
    }
    .onp-style(
        @xs: {
            position: relative;
            top: auto;
        },
        @sm: {
            top: 0;
        }
    )
}

.embedded-content {
    .onp-container-fluid();
    position: relative;
    line-height: 20px;
    margin: 0 -50px;

    h1.header-align {
        font-size: 26px;
        text-align: center;
        font-weight: 400;
    }
}

.alert.login-flash-message + .embedded-content {
    height: 390px;
}

.content-main {
    padding: 0;
}

.myonp-container {
    width: 100%;
}

.myonp-reg {
    width: 100%;
    margin-top: 4% !important;
    &.myonp-reg-confirmed {
        margin-top: 5% !important;
        max-width: 540px;
    }
    .onp-style(@xs: {
        padding: 0 20px;
    })
}

.myonp-transfer {
    width: 100%;
    margin-top: 4% !important;
    .onp-style(@xs: {
        padding: 0 20px;
    })
}

.myonp {
    padding: 0 30px;
    &.myonp-login {
        margin-top: 20px;
        margin-bottom: 20px;
        .onp-row();
        .onp-style(
            @xs: {
                margin-top: 20px;
                margin-bottom: 20px;
                h1, h2 {
                    text-align: left;
                    margin: 10px 0 25px 0;
                }
                h2 {
                    font-size: 24px;
                }
            }
        );
        .btn_log-in,
        .btn_guest,
        .btn_create-account {
            min-width: 140px;
            padding: 10px 13px;
            width: 100%;
            text-align: center;
            text-transform: capitalize;
            font-weight: 400;
            font-size: 16px;
            display: block;
            span {
                font-size: 16px !important;
            }
        }

        .btn_create-account {
            margin-top: 10px;
        }
    }
    .myonp-login-pane {
        &.log-in {
            input[type="text"], input[type="password"], input[type="email"], input[type="search"], input[type="tel"] {
                width: 100%;
                box-sizing: border-box;
            }

            button {
                margin-top: -5px;
                span {
                    font-size: @baseFontSize;
                }
            }
        }
        &.create-account {
            margin-top: 50px;
            .marketing-tempt {
                font-weight: bold;
            }
            .block {
                padding: 4px 0 0 25px;
            }
        }
        .onp-style(@non-xs: {
            .block {
                min-height: 120px;
                margin-bottom: 30px;
            }
        });
        ul {
            list-style: disc;
            padding-left: 0;

            .onp-style(@xs: {
                padding-left: 1em;
            });
        }
        h1,
        h2 {
            font-size: 16px;
            font-weight: 400;
        }
    }
}

.login-popup {
    min-height: 600px;
    .login-block-title {
        text-align: center;
        margin: 30px 0;
        padding-left: 0;
    }
    .myonp-login {
        margin: 0;
    }
    .create-account {
        .block {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    .checkout-block {
        .secure-icon {
            transform: scale(0.75);
            + span {
                font-size: @fontSizeLarge;
                color: #777;
                vertical-align: middle;
            }
        }
    }
    .embedded-content {
        margin: 0;
        padding: 0;

        .myonp {
            padding: 0;
        }
    }
    .dialog-popup-container {
        padding: 0;
        .onp-style(@xs: {
            max-width: 100%;
        });
    }
    .text-error {
        color: @errorText;
    }
    .myonp-reg {
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
        h1 {
            font: 400 26px @headerFontFamily;
            text-align: center;
            margin-bottom: 20px;
        }
        .controls {
            input {
                width: 100%;
            }
        }
        #registration-form {
            input[type=submit] {
                margin-top: 30px;
                min-width: 164px;
                padding: 11px 19px;
                text-shadow: 0 0 0 rgba(0, 0, 0, 0.25);
                font-size: 17px !important;
                line-height: 17px !important;
                font-weight: bold;
                text-transform: uppercase;
                outline: none;
            }
            label {
                float: left;
                margin-right: 10px;
            }
            label[for] {
                cursor: pointer;
            }
            label.error, .help-block {
                display: block;
                font-size: 11px;
                line-height: normal;
                margin-top: 0;
                color: @errorText;
            }
            label.error {
                margin-bottom: 0;
            }
            .help-block {
                margin-bottom: 10px;
            }
        }
    }
}

#facebook-login-button {
    background-color: #4267b2;
    color: #fff;
    display: none;
    padding: 10px 13px;
    width: 100%;
    line-height: 1.28;
    font-family: @latoFontFamily;
    font-size: 16px;
    letter-spacing: .25px;
    text-align: center;

    &:hover {
        cursor: pointer;
    }

    .icon-facebook-sign {
        font-size: 18px;
        position: relative;
        top: 2px;
    }

    span {
        padding-left: 5px;
    }
}

.control-group {
    margin-bottom: 10px;
}

#myonp-reset-pwd {
    margin: 0 auto;
    max-width: 420px;
    text-align: center;

    h3 {
        .title-tag;
        font-size: 2.308em;
        text-align: center;
        margin-bottom: 23px;
    }
    input {
        width: 100%;
    }
    input[type=submit] {
        font-size: @fontSizeLarge;
        margin: 30px 0 20px 0;
    }
    .cancel {
        color: @primaryBlue;
        text-decoration: none;
    }
}
