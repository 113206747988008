@import 'variables.less';
@import "mixins/mixins.less";
@import "mixins/responsive_utilities.less";
@import "~baseTheme/_common/main_banner/index.less";

body {
    font-size: @baseFontSize;
    font-family: @latoFontFamily;
    overflow-x: auto;
    .onp-style (
        @xs: {
            padding-left: 0;
            padding-right: 0;
        }
    );
}

//to override user agent styles
button {
    color: @textColor;
}

// Sprites
#logo {
    .main-sprite();
}

#body-main {
    overflow: visible;
    .onp-style(@big: {
        min-height: @contentMinHeight;
    });
    position: relative;
    &.mobile {
        > div {
            display: none;
        }
        .mobile-menu {
            display: block;
        }
    }
}

#logo {
    width: 232px;
    height: 34px;
    padding: 0;
    margin: 0;
    font-size: .1em;
    text-indent: -9999px;
    position: relative;
    top: 12px;
    float: left;
    a {
        display: block;
        width: 100%;
        height: 100%;
    }
    h1 {
        margin: 0;
        line-height: 34px;
    }
}

.main-banner-img {
    width: 100%;
}

section#prod-hero, .main-banner-container {
    .onp-style(
        @xs: {.onp-row();},
        @sm: {.onp-row(@gutter: 0);}
    );
}

.body-content {
    .onp-container();
    .onp-style(
        @non-xs: {
            min-height: @contentMinHeight;
        },

        @small: {
            margin-top: 49px;
        }
    );
}

.flash-wrapper {
    .onp-row();
    position: relative;
    .flash-message {
       .onp-column(@md: 12, @lg: 12);
    }
}

.content-main {
    padding: 30px 0;
    font-size: 1.083em;
}

section.main {
    margin-bottom: 20px;
}

/** moved from css **/
ul {
    list-style: none;
    margin: 0;
    padding: 0
}

a:active, a:focus, input[type="image"] {
    outline: 0;
}

a img {
    border: 0;
}

.ulink:hover {
    text-decoration: underline;
}

.page-header {
    height: 60px;
    min-width: 1000px;
}

/* header */
.page-header {
    background: #FFF url(/assets/images/repeat_trans_01.png) repeat-x;
}

.page-header .logo {
    position: absolute;
    left: 0;
    top: 10px
}

.page-header .logo.onp-sprite {
    background-position: 0 0;
    width: 231px;
    height: 35px;
}

.page-header .top-right {
    position: absolute;
    right: 0;
    top: 7px;
    height: 23px;
}

.page-header .top-right li {
    display: inline-block;
    zoom: 1;
    font-size: @baseFontSize;
    font-weight: bold;
    height: 18px;
    margin-left: 10px;
    padding-top: 5px;
    vertical-align: top;
}

.page-header .top-right li.devider {
    width: 1px;
    border-right: 1px solid #989796
}

.page-header .top-right li a {
    color: #444;
    text-decoration: none
}

.page-header .top-right li a:hover {
    text-decoration: underline
}

.page-header .logged-in {
    position: absolute;
    right: 0;
    bottom: 5px;
}

.page-header .logged-in b {
    color: #090
}

.col-left {
    width: 160px;
    float: left;
    padding-top: 18px;
}

.col-left h3 {
    margin: 0;
    padding: 5px 0
}

.col-main {
    background: #FFFFFF;
    float: left;
    height: 100%;
    position: relative;
    width: 820px;
}

.col-center {
    width: 100%;
    height: 100%;
    min-height: 500px;
    background: #FFF;
    position: relative;
    margin: auto;
    line-height: normal;
}

.col-center.full {
    position: relative;
    margin-left: 0;
}

.col-center .shad {
    position: absolute;
    width: 20px;
    height: 100%;
    background: url(/assets/images/shadow_repeat_01.png) repeat-y;
}

.col-center .shad.sleft {
    left: -20px;
}

.col-center .shad.sright {
    right: -20px;
    background-position: -20px 0
}

.col-center .panel-container {
    padding: 5px;
}

.col-center .panel-container.center {
    text-align: center;
}


.panel-container .txt {
    font: 14px @baseFontFamily;
    color: #000000;
}

.panel-container .txt_01 {
    font: bold 14px @baseFontFamily;
    color: #000000;
}

.panel-container .txt_02 {
    font: bold 14px @baseFontFamily;
    color: #336699;
}

.panel-container .txt_03 {
    font: bold @baseFontSize @baseFontFamily;
    color: #000000;
}

.panel-container .txt_04 {
    font: bold 16px @baseFontFamily;
    color: #336699;
}

.panel-container .txt_05 {
    font: bold 18px @baseFontFamily;
    color: #336699;
}

.panel-container .ups {
    font: bold 14px @baseFontFamily;
    color: #7F612E;
}

.panel-container .box_title {
    font: bold 14px @baseFontFamily;
    color: #FF6600;
    text-transform: capitalize
}

.panel-container .tx_sm_grey_04 {
    font-size: @baseFontSize;
    color: #777;
}

.panel-container .blue_link {
    font: bold @baseFontSize @altFontFamily;
    color: #336699;
    text-decoration: none;
}

.panel-container .blue_link:link, .blue_link:visited {
    border-bottom: 1px solid #CEE6FF;
}

.panel-container .blue_link:hover {
    color: #336699;
    border-bottom: 1px solid #ff6600;
}


/* banner */
.banner {
    position: relative;
    width: 780px;
    height: 250px;
    margin: 0 auto;
    padding: 0;
    border: 1px solid #EFEFEF;
    background-position: bottom right;
    background-repeat: no-repeat;
}

.banner h1 {
    position: relative;
    color: #000;
    margin: 0;
    padding: 20px 0 0 30px
}

.banner .txt {
    color: #000;
    padding: 0 30px
}

.banner p {
    padding: 0 30px
}

.banner ul {
    text-align: left
}

.banner ul li {
    font-size: @baseFontSize;
    color: #777;
    margin-top: 8px
}

.banner .sub {
    font-size: @baseFontSize;
    font-weight: bold;
    color: #333;
    padding: 5px;
}

.banner ul ul {
    margin-left: 15px;
}

.banner ul ul li {
    margin-top: 2px
}

/* icons */
.payment {
    position: absolute;
    top: 5px;
    right: 20px;
    height: 50px;
}

.payment li {
    position: relative;
    display: inline-block;
}

.payment li.cc {
    width: 600px;
    text-align: right
}

.payment .cc li {
    display: inline-block;
    margin: 2px 2px 0 0
}

.payment .cc .paypal {
    background-position: -231px -45px
}

.payment .cc a {
    display: block;
    width: 100%;
    height: 100%
}

.payment .cc .google.onp-sprite {
    background-position: -49px -76px;
    margin: 2px 0 0 0;
    width: 142px;
}

.margin {
    margin-right: 220px;
}

body .ui-widget-content {
    color: inherit;
}

.g-recaptcha {
    .onp-style(
        @xs: {
            .transform(scale(0.9));
            .transform-origin(100% 0);
        }
    );
}
/** end css **/

.onp-style (
    @xs: {
        .mobile-adjusted {
            font-size: @baseFontSize;
        }
        .body-content {
            padding-bottom: @mobileFooterHeight;
        }
    }
);

.home-page-slider {
    .onp-container();
    margin-top: 10px;
}

.old-browser-warning {
    padding: 15px 0;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    z-index: @overlayZIndex;
    .onp-style (
        @xs: {
            margin-top: 49px;
            margin-bottom: -40px;
            z-index: @menuZIndex - 1;
        }
    );

    .close {
        right: 10px !important;
    }
}
