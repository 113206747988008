@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/grid.less';

.mobile-toolbar-row {
    .onp-container();
    width: 100%;
    font-size: @fontSizeLarge;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;

    .arrow-back::before {
        float: left;
    }
    .arrow-forward::after {
        float: right;
    }
}
