@import '../variables.less';
@import '../mixins/mixins.less';
/* Buttons */

/* .btn styles */
/* Common */

.btn {
    font-family: @buttonFontFamily;
    &,
    &:focus {
        border: 0;
        background: #a9a9a9;
        text-shadow: 0 0 0 rgba(255,255,255,0.75);
        text-transform: uppercase;
        color: @btnTextColor !important;
        outline: none;
    }
    font-weight: @buttonFontWeight;
    &:hover {
        background: #979797;
    }
    &:active {
        background: #979797;
        box-shadow: none;
        // override bootstrap styles
        &:focus {
            outline: none;
        }
    }
    &.disabled,
    &[disabled] {
        background: #c9c9c9;
        color: #eee;
    }
    &.btn-xs {
        font-size: @baseFontSize;

        &.btn-wide {
            padding-right: @padding-xs-horizontal * 2;
            padding-left: @padding-xs-horizontal * 2;
        }

    }
    &input {
        height: 35px;
        &.btn-large {
            height: 44px;
        }
    }
    &.btn-primary {
        font-weight: bold;
        &,
        &:focus {
            background: @actionButtonColor;
            text-shadow: 0 0 0 rgba(0,0,0,0.25);
        }
        &:hover {
            background: @actionButtonHover;
        }
        &:active {
            background: @actionButtonActive;
        }
        &.disabled,
        .btn[disabled] {
            opacity: 0.75;
        }
    }
    &.btn-secondary {
        .btn-secondary;
    }
    &.btn-arrow {
        .arrow-button();
    }
    &.btn-shine {
        position: relative;
        .shine(10s);
    }
    // Textual button
    &.btn-text {
        border: 1px solid #bfbfbf;
        background: none;
        text-transform: none;
        flex-shrink: 0;
        &,
        &:visited {
            color: @primaryGray !important;
        }
        &:focus,
        &:hover,
        &:active {
            border-color: @primaryBlue;
            font-weight: normal;
        }
        &-white {
            border: none;
            &,
            &:visited {
                color: #fff !important;
            }
            &:focus,
            &:hover,
            &:active {
                background-color: #fff;
                color: @primaryBlue !important;
            }
        }
    }
    &.btn-icon {
        display: flex;
        align-items: center;
        .btn-icon-el {
            position: relative;
            left: -3px;
        }
        &.btn-icon-red {
            .btn-icon-el {
                color: @primaryRed;
            }
        }
    }
    &.btn-arrow-left::before {
        display: inline-block;
        margin-right: 0.6em;
        content: "\f04b";
        font-family: FontAwesome;
        font-size: 0.8em;
        vertical-align: bottom;

        .transform(rotate(180deg));
    }
    &.btn-arrow-circle {
        border-radius: 50%;
        width: 28px;
        height: 28px;
        position: relative;
        float: left;
        padding: 4px 8px;
        &.btn-arrow-right::after {
            margin-left: 0.2em;
        }
    }
        /* Sizes */
    &.btn-medium {
        min-width: 130px;
        padding: 8px;
        font-size: 14px !important;
        line-height: 14px !important;
    }
    &.btn-large {
        .btn-large;
    }
    &.cancel:after {
        margin-left: 0.5em;
        content: "X";
        font-family: @sansFontFamily;
        font-size: 0.9em;
        font-weight: 800;
    }
}

.btn-large () {
    min-width: 164px;
    padding: 11px 19px;
    text-shadow: 0 0 0 rgba(0,0,0,0.25);
    font-size: 17px !important;
    line-height: 17px !important;
}

.btn-secondary () {
    &,
    &:focus {
        background: @secondaryButtonColor;
    }
    &:hover {
        background: @secondaryButtonHover;
    }
    &:active {
        background: @secondaryButtonActive;
    }
    &.is-non-interactive {
        &,
        &:focus,
        &:hover,
        &:active {
            cursor: default;
            background: @secondaryButtonColor;
        }
    }
}

/* end of .btn */

/* ONP buttons */

.onp-button {
    .onp-button();
}

.onp-button-disabled {
    .onp-button-disabled();
}

.helpdesk-button, .gallery-button {
    display: inline-block;
    padding: 8px 36px;
    .gradient-vertical(@btnGradStart, @btnGradEnd);
    text-transform: uppercase;
    text-align: center;
    color: @white;
    font-family: @buttonFontFamily;
    font-weight: normal;
    font-size: 16px;
    &:hover, &:visited, &:active {
        background-color: @secondaryButtonHover;
        color: @white;
    }
    &:hover {
        .gradient-vertical(@btnGradEnd, @btnGradStart);
    }
}
