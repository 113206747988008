@import (reference) '~resources/less/themes/base/variables.less';

.mobile-footer-toolbar {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0;
    position: fixed;
    right: 0;
    left: 0;
    z-index: @zindex-navbar-fixed;

    &.mobile-navigation-toolbar,
    &.mobile-action-toolbar {
        height: @mobileActionToolbarHeight;
        text-transform: uppercase;
        box-shadow: 0 -2px 10px rgba(0,0,0,0.5);
    }
}
