@import '../../variables.less';

h2 {
    font-size: 16px
}

h3 {
    font-size: 14px
}

h4 {
    font-size: 13px
}

.myonp-container {
    margin: 0 auto;
    &.myonp-container-reset {
        margin-top: 70px;
        .onp-style(
            @xs: {
                margin-top: 30px;
            }
        );
    }
}

.myonp-container.single {
    width: 600px;
}

.hidden {
    display: none
}

.myonp label {
    display: block;
    float: left;
    text-align: right;
    margin-right: 10px
}

.myonp .fields {
    border: 1px solid #DFDFDF;
    background: #FFF;
    padding: 10px
}

.myonp .field {
    padding: 5px;
    clear: both;
}

.myonp .submit {
    width: 300px;
    margin: 0 auto;
    text-align: center;
    padding: 10px
}

.myonp h4.error {
    color: #900;
    padding: 5px;
    margin: 0
}

.myonp .required {
    color: #900;
    display: none
}

.center {
    width: 300px;
    margin: 0 auto;
    text-align: center
}

.center-wide {
    width: 600px;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
}

.offset100 {
    margin-left: 100px;
}

#product-start {
    margin-left: 250px;
    margin-top: 25px;
}

/* myonp_order_details */
.myonp.order-details h2 {
    margin: 0;
    padding: 8px 0;
    font-size: 16px
}

.myonp.order-details h2 b {
    color: #369
}

.myonp .details {
    width: 320px;
    float: left;
    margin-right: 15px
}

.myonp .details .detail {
    margin-bottom: 4px;
}

.myonp .address {
    padding: 5px 10px;
    background: #FFF
}

.myonp .actions {
    background: #FAFEFF;
    list-style: none;
    padding: 5px 5px;
    border: 1px solid #DFDFDF;
    margin: 0
}

.myonp .actions li {
    padding: 2px 0
}

.myonp .actions li a {
    padding: 5px;
}

.myonp .actions li a:hover {
    text-decoration: underline
}

.myonp .actions em {
    padding: 5px 0 5px 20px
}

.myonp .order-items {
    width: 430px;
    float: left;
    border: 1px solid #DFDFDF;
    background: #EFEFEF;
    padding: 5px;
    margin-bottom: 20px
}

.myonp .order-items .item {
    margin-bottom: 5px;
    padding: 5px;
    background: #FFF;
}

.myonp .order-items .item.odd {
    background: #FCFCFC;
}

.myonp .order-items .item h3 {
    padding: 2px;
    margin: 0
}

.myonp .order-items .item .desc {
    padding: 2px;
    margin: 0;
}

.myonp .order-items .item .thumbs {
    min-width: 150px;
    vertical-align: top;
}

.myonp .order-items .item.printed img {
    border: 1px solid #000;
    margin: 5px
}

.myonp .order-items .item .desc a:hover {
    text-decoration: underline
}

/* edit-user */
.edit-user th {
    text-align: left
}

/* mailing */
.mailing-lists {
    position: relative;
    width: 100%;
    height: 900px;
}

#ml_main_iframe {
    height: 100%;
    width: 100%;
    border: 0;
}

#ml_csv_iframe {
    height: 0;
    width: 0;
    border: 0;
}

/* end main navigation */
/* Begin Content Template Atributes */

.content_sub_header {
    background-image: url(/assets/images/myonp/nav_top_mid.gif);
    background-repeat: repeat-x;
    color: #FFFFFF;
    font: bold 14px @altHeaderFontFamily;
    text-align: center;
    height: 25px;
}

#reseller {
    border: 1px solid #999999;
    background-color: #fffce7;
}
.opacity {
    position: relative;
    background-color: #fff;
    opacity: .70;
    z-index: 1;
    filter: alpha(opacity=70);
}

.content_header_text {
    text-align: center;
    font-size: @baseFontSize;
    padding: 5px;
    font-style: normal;
}

.tooltip {
    color: #ddd;
    background-color: rgb(48, 48, 48);
    background-color: rgba(48, 48, 48, 0.9);
    _background-color: #333;
    border: #333 1px solid;
    -moz-border-radius: 10px; /* Firefox */
    -webkit-border-radius: 10px; /* Safari, Chrome */
    border-radius: 10px; /* CSS3 */
    padding: 10px;
    width: 200px;
    font-family: @altHeaderFontFamily;
    font-size: @baseFontSize;
}

.myonp .field .error {
    text-align: center;
}

.header-align {
    text-align: center;
}

.page-header {
    background: none
}

.col-center {
    vertical-align: top;
}

.col-center input[type="text"], .col-center input[type="password"] {
    margin: 0;
    height: auto;
    padding: 0;
}

.col-center input[type="checkbox"] {
    margin: 4px;
}

.col-center select {
    height: auto;
    padding: 0;
}

.col-center p {
    margin: 12px 0;
}

.col-center table {
    border-collapse: separate;
    border-spacing: 0 2px;
}

.full.col-center {
    width: 100%
}

/* left-col */
.col-left {
    padding: 0;
    width: 145px;
    left: 0
}

.shad {
    display: none
}
