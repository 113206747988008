@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';
@import (reference) '~resources/less/themes/base/mixins/grid.less';

section#prod-hero, .main-banner-container {
    position: relative;
    margin-bottom: 0;
    margin-top: 10px;
    &.clickable-banner {
        cursor: pointer;
    }
    .bnr {
        position: relative;
        background-color: @borderColor;

        &.static-header-banner {
            height: 280px;
            background-size: 100% auto;
            background-repeat: no-repeat;

            .onp-style(
                @lg: {
                    height: 400px;
                },
                @sm: {
                    height: 215px;
                    margin-bottom: 10px;
                },
                @xs: {
                    height: 213px;
                    background-color: transparent;
                }
            );
        }
    }
    .txt {
        position: absolute;
        top: 160px;
        left: 695px;
        width: 240px;
        font-size: .93em;
        h1 {
            display: none;
        }
        ul {
            list-style: disc;
            margin-left: 15px;
        }
        li {
            line-height: 25px;
        }
    }
    .video {
        z-index: 1;
    }
    .video-overlay {
        position: absolute;
        z-index: 2;
        min-width: 100%;
        text-align: center;
    }
}

.main-banner-img {
    vertical-align: top;
    width: 100%;
}

.main-banner-text {
    color: @white;
    max-width: 450px;
    font-size: 15px;
    .onp-style(
        @md: {
            top: 16px;
            &.bc-banner-text {
                top: 0;
            };
            font-size: @baseFontSize;
        },
        @sm: {
            top: 20px;
            left: 20px;
            font-size: 12px;
        }
    );
    &.main-banner-text-black {
        color: @black;
    }

    &.cookie-banner-text {
        .onp-style(
            @sm: {
                top: 0px;
                left: 20px;
                font-size: @fontSizeXSmall;
                max-width: 400px;
            },
            @md: {
                max-width: 400px;
            },
            @xs: {
                display: none;
            }
        );
    }

    h1 {
        font-size: 300%;
        line-height: 41px;
        margin-bottom: 25px;
    }

    ul {
        list-style: disc;
        padding-left: 15px;
    }

    .banner-promo-text {
        display: block !important;
        font-weight: bold;
        font-size: 140%;
        margin-bottom: 30px;
        .onp-style(
            @md: {
                margin-bottom: 14px;
            }
        );
    }
    .banner-text {
        .onp-hidden(@small: true);
        margin-bottom: 15px;
    }
    .btn-primary {
        margin-top: 15px;
        text-transform: capitalize;
        .onp-style(
            @md: {
                margin-top: 0;
            }
        );
    }
}
