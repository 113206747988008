@import '../../variables.less';
@import '../../mixins/mixins.less';

.selectboxit-container {
    * {
        font-size: @baseFontSize;
        font-family: @baseFontFamily;
    }
    .selectboxit-option-icon {
        font-family: FontAwesome;
    }

    select {
        //override inline z-index: 999999, otherwise the select is selectable when it's covered with other block
        z-index: @selectboxItZIndex !important;
    }
    .selectboxit-btn {
        background: #f5f5f5 none;
        border: 1px solid @primaryGrayLighter;
        border-color: @primaryGrayLighter @primaryGrayLighter @selectBoxItBottomBorder;
        .box-shadow(none);
        &.selectboxit-enabled {
            &, &:hover, &:focus, &:active {
                color: @textColor;
            }
        }
    }
    span, .selectboxit-options a {
        line-height: 30px;
        min-height: 30px;
        .description {
            font-family: @baseFontFamily;
            display: inline-block;
        }
        em {
            font-family: @serifFontFamily;
            font-style: italic;
            font-size: inherit;
        }
    }
    .selectboxit-options {
        z-index: @selectboxItZIndex;
        max-height: 295px;

        li a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .selectboxit-option {
        .gradient-vertical (#f6f6f6, #f4f4f4);
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #fff;
        .selectboxit-option-anchor {
            color: @textColor;
        }
    }
    .selectboxit-text, .selectboxit-option-anchor  {
        font-size: @baseFontSize;
        font-family: @baseFontFamily;
        white-space: nowrap;
    }
    .selectboxit-arrow-container {
        border-left: 1px solid #ddd;
    }
    .selectboxit-list > .selectboxit-focus {
        border-top: solid 1px #fff;
        .gradient-regular();
        > .selectboxit-option-anchor {
            background-image: none;
            background: transparent;
            color: @textColor;
        }
    }
}

#itemId_selectorSelectBoxIt {
    max-width: 420px;
}

#fMagnetItemIdSelectBoxIt {
    max-width: 200px;
}

#filling-selectSelectBoxIt,
#color-selectSelectBoxIt,
#size-selectSelectBoxIt,
#macaron-flavor-selectSelectBoxIt {
    max-width: 186px;
}
