input.checkbox-simple {
    display: none;
}
label.checkbox-simple {
    cursor: pointer;
    height: auto;
    margin: 0;
    padding: 0 0 0 25px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -7px;
        left: 0;
        width: 15px;
        height: 15px;
        border: 2px solid @primaryBlueDark;
        background: @white;
    }
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -3px;
        left: 4px;
        width: 7px;
        height: 7px;
        background: @primaryBlueDark;
        opacity: 0;
        transition: all .2s;
    }

    &.checkbox-big {
        padding: 15px 0 0 40px;

        &:before {
            width: 30px;
            height: 30px;
        }

        &:after {
            content: '\2713';
            color: @primaryBlueDark;
            background: transparent;
            left: 8px;
            font-size: 20px;
        }
    }
}

input.checkbox-simple:checked + label.checkbox-simple {
    font-weight: bold;
}

input.checkbox-simple.checkbox-big:checked + label.checkbox-simple.checkbox-big {
    font-weight: normal;
}

input.checkbox-simple:disabled + label:before {
    border-color: @grayLight;
}

input.checkbox-simple:disabled + label:after {
    display: none;
}

input.checkbox-simple:checked + label.checkbox-simple:after{
    opacity: 1;
}

input.checkbox-simple:not(:checked) + label.checkbox-simple:hover:after {
    opacity: 0.7;
    .onp-style(@xs: {
        opacity: 0;
    });
}

input.checkbox-simple:focus + label.checkbox-simple:before {
    box-shadow: 0 0 0 3px fade(@primaryBlueDark, 50%);
}
