@import '~resources/less/themes/base/variables.less';

@headerHeight: 36px;
.featured-header {
    margin: @headerHeight 0;
    .featured-header-hr {
        position: relative;
        height: 2px;
        border-top: 2px solid @primaryGreen;
        top: @headerHeight / 2;
    }
    .featured-header-name {
        display: inline-block;
        position: relative;
        margin: 0;
        padding-right: 30px;
        font-size: @headerHeight;
        line-height: @headerHeight;
        text-transform: uppercase;
        font-weight: bold;
        background-color: @bodyBackground;
    }
}
