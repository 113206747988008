@import '../variables.less';

.ui-widget {
    font-family: @sansFontFamily;
    font-size: @baseFontSize;
    line-height: normal;

    button {
        font-family: @latoFontFamily;
        font-weight: @buttonFontWeight;
    }
}

.ui-widget-overlay {
    background: @black;
    opacity: .5;
}

.ui-widget-content {
    border: 0;
    a {
        color: @linkColor;
    }
}

.ui-corner-all {
    border-radius: 0;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    font-weight: bold;
    color: @white;
    border: 1px solid @white;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus, .ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
    color: @white;
    border: 1px solid @white;
    background: none;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
    opacity: 1;
}

.ui-state-active .ui-icon {
    background-image: url("/assets/libs/jquery-ui/images/ui-icons_777777_256x240.png");
}

// Dialog
.ui-dialog {
    font-size: @baseFontSize;
    box-shadow: 0 0 8px rgba(68,68,68,0.6);
    overflow: visible;
    .ui-dialog-titlebar {
        &.ui-widget-header {
            background: none;
            border: 0;
        }
    }
    .ui-dialog-titlebar-close {
        display: block;
        .ui-icon-closethick {
            width: 16px;
            height: 16px;
            background: url("/assets/images/site/onp_main_sprite.png") -292px -26px no-repeat;
        }
        &.ui-state-hover {
            border: none;
        }
    }
    .ui-dialog-title {
        color: @navbarText;
        font-size: 1.5em;
        font-weight: bold;
    }
    h3.title-tag {
        font-size: 1.95em;
        text-align: center;
        margin-bottom: 25px;
    }
    .ui-dialog-content {
        padding: 0 50px 30px;
        a {
            color: @primaryBlueActive;
        }
    }
    .ui-resizable-se {
        position: absolute;
    }
    &.loading-popup {
        text-align: center;
        width: 320px;
        height: 19px;
        overflow: hidden;
        z-index: @overlayZIndex + 1;
        .ui-dialog-content {
            padding: 0;
        }
    }
    &.transparent {
        background: transparent;
        padding: 0;
        box-shadow: none;
    }
    &.noTitleStuff {
        .ui-dialog-titlebar {
            display: none;
        }
    }
}

// Datepicker
.ui-datepicker {
    padding: 0;
    z-index: @selectboxItZIndex + 1 !important;
    border: 1px solid @primaryBlueDarkest;
    .ui-datepicker-header {
        background-color: @primaryBlueDarkest;
        color: @white;
        .ui-icon {
            background-image: url("/assets/images/site/onp_main_sprite.png");
            &.ui-icon-circle-triangle-w {
                background-position: -420px -220px;
            }
            &.ui-icon-circle-triangle-e {
                background-position: -440px -220px;
            }
        }
    }
    .ui-state-default {
        background: @white;
    }
    .ui-state-disabled .ui-state-default {
        color: @primaryBlueDarkest;
        border-left: none;
        border-bottom: none;
        text-align: center;
    }
    .ui-datepicker-other-month .ui-state-default {
        color: @primaryGray;
    }
    .ui-datepicker-prev-hover, .ui-datepicker-next-hover {
        border: none;
        margin: 1px;
    }
    .ui-datepicker-current-day {
        a, a.ui-state-active, span {
            background-color: @primaryBlue;
            color: @white;
            border-right: 1px solid @white;
        }
    }
    table {
        margin: 0;
    }
    th {
        text-transform: uppercase;
        padding: .3em;
        font-size: 1.1em;
        color: @primaryBlueDarkest;
        border-bottom: 1px solid @primaryGrayLighter;
    }
    td {
        padding: 2px;
        border: 0;
        span, a {
            border-radius: 50%;
            width: 26px;
            height: 26px;
            margin: 0 auto;
            padding: .4em .1em;
        }
    }
    a.ui-state-default {
        border: none;
        color: @white;
        text-align: center;
        background: @primaryBlueDarkest;
        border-right: 1px solid @white;
    }
}

.ui-datepicker-close {
    background: @primaryBlue !important;
}

.ui-datepicker-current {
    display: none;
}

.ui-datepicker-today {
    background: @white;
    .ui-state-default {
        background-color: @primaryGrayLighter;
    }
}
