.pagination-form {
    .page-redirector {
        background: transparent url("/assets/images/site/onp_main_sprite.png") no-repeat;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        height: 20px;
        width: 18px;
    }

    .page-first {
        background-position: -519px -218px;
    }

    .page-last {
        background-position: -575px -218px;
    }

    .page-prev {
        background-position: -538px -218px;
        margin-right: 5px;
    }

    .page-next {
        background-position: -555px -218px;
        margin-left: 5px;
    }

    .page, .current {
        font-size: 16px;
        vertical-align: middle;
        padding: 0 2px;
    }
}
