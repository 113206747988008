@import (reference) '~resources/less/themes/base/variables.less';

.mobile-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: @mobileToolbarColor;
    color: @mobileToolbarTextColor;
    font-family: @latoFontFamily;
    height: @mobileToolbarHeight;
    a {
        text-decoration: none;
        display: block;
        &, &:visited, &:hover, &:active {
            color: @mobileToolbarTextColor;
            font-weight: bold;
        }
    }
}
