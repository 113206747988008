@import 'variables.less';
@import 'mixins/grid.less';
@import 'mixins/responsive_utilities.less';
@import 'mixins/mixins.less';
@import (reference) '../../../../../../node_modules/bootstrap/less/forms.less';
@import 'elements/simplified/all.less';
@import '~baseTheme/_common/featured_header/index.less';
@import '~baseTheme/_common/tabs_bar/index.less';
@import '~baseTheme/_common/cut_zone/index.less';

// Links
a, a:active, a:focus, button, button:focus, button:active, .btn:focus,
input[type="radio"], input[type="radio"]:focus, input[type="checkbox"], input[type="checkbox"]:focus {
    outline: none;
}

a {
    color: @linkColor;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    &:hover, &:active, &:focus {
        color: @linkColorHover;
        text-decoration: none;
    }
    &:visited {
        color: @linkColor;
    }
}

// Headers
h1, .h1,
h2, .h2,
h3, .h3 {
    margin-top: (@baseLineHeight / 2);
    margin-bottom: (@baseLineHeight / 2);
}

h1 {
    font-size: 24px;
    line-height: 29px;
}

h2 {
    font-size: 16px;
    line-height: 24px;
}

h3 {
    font-size: @baseFontSize;
    line-height: 20px;
}

em {
    font-family: @serifFontFamily;
}

img {
    max-width: 100%;
}

// Forms
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    .form-control;
    width: auto;
    display: inline-block;
    &[disabled], &[readonly] {
        opacity: 0.5;
    }
}

select {
    min-width: 40px;
}

input, select {
    margin-bottom: 10px;
}

input[type="radio"], input[type="checkbox"] {
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
}

.radio, .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 5px;
    padding-left: 20px;
}

input.textInput {
    .input-text();
}

textArea.textInput {
    .input-text();
}

.input-text {
    .input-text();
}

.input-append {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: middle;
    font-size: 0;
    white-space: nowrap;
    input[type=text], input[type=search] {
        .input-append-text-field();
    }
    .btn {
        position: relative;
        left: -20px;
        z-index: @guideLinesZIndex;
        height: 35px;
        vertical-align: top;
    }
    label.error {
        position: absolute;
    }
}

//allows to handle clicks on disabled inputs
.disabled-input-overlay-container {
    position: relative;
}

.disabled-input-overlay {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 1;
}

label {
    display: inline-block;
    font-size: @baseFontSize;
    font-weight: normal;
    line-height: @baseLineHeight;
    input[type="radio"], input[type="checkbox"] {
        margin: 1px 0 0;
    }
    &[for] {
        cursor: pointer;
    }
    &.required:after {
        content: "*";
        color: red;
        padding-left: 3px;
    }
}

label.error {
    font-size: @baseFontSize;
    display: block;
    margin-bottom: 0;
}

.text-error {
    color: @errorText;
}

.error {
    color: @errorText;
    .textInput {
        border: 1px solid @errorText;
    }
}

.help-block {
    color: @errorText;
}

figure {
    margin: 0;
}

.form-horizontal {
    .control-group {
        margin-bottom: 15px;
        &.short {
            margin-bottom: 0;
            .checkbox {
                padding-top: 0;
            }
        }
        &.optin {
            margin-top: 27px;
        }
        &.submt {
            margin-top: 25px;
        }
    }
    .controls {
        @media (min-width: 481px) {
            margin-left: 125px;
        }
        &.plain-text {
            padding-top: 9px;
        }
        .checkbox {
            padding-left: 30px;
            font-size: .923em;
        }
        input[type="checkbox"] {
            margin: 4px 0 0 -30px;
        }
    }
    label {
        font-size: inherit;
    }
    .control-label {
        width: 94px;
        float: left;
    }
    input[type="text"], input[type="password"], input[type="email"], input[type="tel"] {
        .input-text();
        @media (min-width: @screenSmMin) {
            width: 322px;
            max-width: 100%;
        }
    }
    .double-button-row {
        .onp-style(
            @non-xs: {
               width: 322px;
           }
        );
        .btn:last-child {
            float: right;
            .onp-style(
                @xs: {
                    float: none;
                }
            );
        }
    }
    select {
        display: inline-block;
        width: auto;
    }
    input[type="checkbox"] {
        margin-right: 15px;
    }
    #button-cancel {
        line-height: 27px;
    }
}

// Headers
.title-tag {
    .title-tag();
}

.title-tag-sm {
    font-size: 16px;
}
.title-tag-md {
    font-size: 30px;
}
.title-tag-lg {
    font-size: 50px;
}

.accent {
    .accent();
}

// Shadows
.dark-shadow {
    content: "";
    display: block;
    height: 18px;
    position: absolute;
    top: 0;
    background-position: 0 -120px;
    z-index: 100;
}

// Dropdowns
.dropdown-backdrop {
    position: static; //fixes dropdown links on mobile
}

.dropdown-menu {
    .border-radius(0);
    z-index: @logoZIndex - 1;
}

.dropdown-menu li > a:hover, .dropdown-menu li > a:focus, .dropdown-submenu:hover > a {
    background-image: none;
    background-color: transparent;
    filter: none;
}

.dropdown-button {
    border: 1px solid @borderColor;
    line-height: 26px;
    .border-radius(0);
}

// Popovers
.popover {
    font-size: inherit;
}

// Alerts
.alert {
    padding: 8px 15px;
}

.margin {
    margin-right: 0;
}

.dim {
    opacity: .4;
}

.hid {
    visibility: hidden;
}

// carousel navigation dots
.carousel-nav {
    text-align: center;
    background-position: 0 -20px;
    a {
        width: 10px;
        height: 10px;
        background-position: -240px -50px;
        display: inline-block;
        padding: 3px;
        margin-top: 18px;
        border: none;
    }
    a.active {
        background-position: -256px -50px;
    }
}

.disabled {
    color: @grayLight;
}

div.or-separator {
    width: 48px;
    height: 215px;
    position: relative;
    left: 30px;
    max-width: 60px;
    min-width: 48px;
    text-indent: -9999px;
    font-size: .1em;
    display: table-cell;
    float: right;
}

.ptag {
    background: url("/assets/images/site/pricetag_sprite.png") no-repeat;
    .title-tag();
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.3);
    text-align: center;
    padding-left: 24px;
}

.logos {
    span {
        background: url("/assets/images/site/footer_logos.png") no-repeat;
        display: inline-block;
        width: 43px;
        height: 26px;
        text-indent: -9999px;
        margin-bottom: 9px;
        vertical-align: middle;
    }
    div {
        display: inline-block;
    }
    span.ic-visa {
        background-position: -47px 0;
    }
    span.ic-amex {
        background-position: -94px 0;
    }
    span.ic-disc {
        background-position: -141px 0;
    }
    span.ic-echeck {
        background-position: -188px 0;
    }
    span.ic-paypal {
        background-position: -235px 0;
    }
    span.ic-jcb {
        background-position: -147px -34px;
    }
    span.ic-bbb {
        width: 95px;
        height: 28px;
        background-position: -1px -35px;
    }
    .ic-verisign {
        vertical-align: middle;
    }
    span.ic-goog {
        width: 43px;
        height: 28px;
        background-position: -100px -35px;
    }
    span.ic-bank-transfer {
        width: 39px;
        height: 24px;
        background-position: -192px -35px;
    }
}

.checkout-section {
    .onp-row();
    margin-top: 30px;
    margin-bottom: 30px;
    > div {
        .onp-column(@md: 6, @lg: 6);
        &.error {
            float: none;
            width: 100%;
            margin-bottom: 20px;
            font-weight: bold;
        }
    }
}

.checkout-continue-btn {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.secure-icon {
    .main-sprite();
    background-position: -240px -4px;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
}

.product-line-through {
    position: relative;
    &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: @primaryBlue;
        .box-shadow (0 0 2px 0 rgba(42, 148, 187, 0.75));
        position: absolute;
        top: 9px;
        left: 0;
    }
}

.rq:after {
    position: absolute;
    text-indent: 0.3em;
    content: "*";
    color: @primaryOrangeActive;
    font-size: 1.384em;
    white-space: nowrap;
}

#passwords-mismatch, #passwords-short {
    display: none;
}

.saleprice {
    font: @baseFontSize @baseFontFamily !important;
    font-style: italic;
    color: @primaryOrangeActive;
    font-weight: bold;
    display: inline-flex !important;
    align-items: center;
    line-height: normal !important;
}

.strike-price {
    font: @baseFontSize @baseFontFamily !important;
    text-decoration: line-through;
    display: inline-flex !important;
    align-items: center;
    line-height: normal !important;
}

.product-option-description {
    color: @textSecondaryColor;
    &.alert-description {
        color: @errorText;
    }
}

.header-block {
    .gradient-header-block();

    > *:first-child {
        display: inline-block;
    }
    .progress-bar-container {
        .onp-style(@non-xs: {
            float: right;
        });
    }
}

.arrow-forward {
    .arrow-forward();
}

.arrow-back {
    .arrow-back();
}


.page-subheading {
    .page-heading;
    font-weight: normal;
    font-size: 29px;
}

.page-subheading-sm {
    .page-heading;
    font-weight: normal;
    font-size: 24px;
}

.page-subheading-aside {
    float: right;
}

.pickup-note {
    margin: 10px 0;
    color: @primaryRed;
    font-style: italic;
}

.product-group-selector {
    width: 100%;
    max-width: 340px;
    float: left;
    margin-bottom: 10px;
}

.file-input-remove {
    position: relative;

    .remove {
        position: absolute;
        bottom: 5px;
        right: 0;
        cursor: pointer;
        display: none;
        &:hover {
            background: url(/assets/images/remove.png);
        }
    }
}

// disable user agent style to use bootstrap dotted underline
abbr[title], abbr[data-original-title] {
    text-decoration: none;
}

legend.title {
    margin-bottom: unset;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    border: none;
}

.body-content {
    h1, h2, h3, h4, h5 {
        font-family: @headingsFontFamily;
        font-weight: bold;
    }
}

.page-heading {
    font-family: @headingsFontFamily;
    font-weight: @headingsFontWeight;
    font-size: 38px;
    font-style: normal;
    line-height: 1em;
    position: relative;
    text-transform: uppercase;
}
