@import '../variables.less';

@import '../../../../../../../node_modules/bootstrap/less/mixins.less';
@import '../../../../../../../node_modules/bootstrap/less/normalize.less';
@import '../../../../../../../node_modules/bootstrap/less/scaffolding.less';
@import '../../../../../../../node_modules/bootstrap/less/type.less';
@import '../../../../../../../node_modules/bootstrap/less/tables.less';
@import '../../../../../../../node_modules/bootstrap/less/forms.less';
@import '../../../../../../../node_modules/bootstrap/less/buttons.less';
@import '../../../../../../../node_modules/bootstrap/less/dropdowns.less';
@import '../../../../../../../node_modules/bootstrap/less/button-groups.less';
@import '../../../../../../../node_modules/bootstrap/less/input-groups.less';
@import '../../../../../../../node_modules/bootstrap/less/pagination.less';
@import '../../../../../../../node_modules/bootstrap/less/pager.less';
@import '../../../../../../../node_modules/bootstrap/less/navs.less';
@import '../../../../../../../node_modules/bootstrap/less/labels.less';
@import '../../../../../../../node_modules/bootstrap/less/alerts.less';
@import '../../../../../../../node_modules/bootstrap/less/close.less';
@import '../../../../../../../node_modules/bootstrap/less/popovers.less';
@import '../../../../../../../node_modules/bootstrap/less/utilities.less';

@import '../mixins/mixins.less';
@import '~resources/less/themes/base/elements/buttons.less';
@import '../mixins/responsive_utilities.less';
@import '../pages/standalone/myonp.less';

@import '../elements.less';
@import '../elements/simplified/text.less';
@import '../elements/simplified/dropdown.less';
@import '../elements/design_face_preview.less';
@import '../style.less';
@import '../header.less';
@import '../footer.less';

@import '../pages/cart.less';
@import '../pages/vip_rewards.less';
@import '../pages/myonp.less';

@import '../widgets/action_in_progress_overlay.less';
@import '~baseTheme/_common/login_popup/index.less';
@import '../widgets/pagination.less';
@import '../widgets/popup.less';
@import '../widgets/standalone/selectbox.less';
@import '../widgets/ui_widgets.less';
@import "../widgets/intl_tel_input.less";

@import 'elements.less';

.mobile-elements();

// for google optimize anti-flicker
.async-hide {
    opacity: 0 !important;
}

body {
    font-size: @baseFontSize;
}

.body-content {
    min-height: inherit;
}

.radio, .checkbox {
    padding-left: 0;
    .mobile-option {
        padding-left: 0;
    }
}

.selectboxit-container {
    .onp-style(@small: {
        max-width: 100%;

        .selectboxit-text {
            max-width: 84% !important;
        }
        .selectboxit {
            max-width: 100%;
        }
    });
}
