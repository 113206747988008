@import "../../variables.less";
@import (reference) "text.less";

.input-group-simple {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;

    input[type=text] {
        &:extend(.text-simple);
        &:focus,
        &:hover {
            border-color: @primaryBlue !important;
            box-shadow: none !important;
        }
    }
    .input-group-append-simple {
        margin-left: -1px;
        display: flex;
        line-height: @formElementHeight;
        height: @formElementHeight;
    }
    label.error {
        position: absolute;
    }
}
